var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.refresh && _vm.data.properties.filed_content != 'Hide')?_c('div',[(!_vm.loading && !_vm.profilePage && _vm.data.properties.filed_content != 'Hide')?_c('div',{style:(_vm.getElementStyle)},[_c('el-row',{attrs:{"align":"middle"}},[(!_vm.data.properties.hideLabel)?_c('el-col',{style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel && _vm.data.properties.filed_content != 'Hide')?_c('label',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label || _vm.data.label))]):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),(_vm.data.allow_to_create && _vm.hasLabel)?_c('span',[_c('el-popover',{attrs:{"placement":"top-start","width":"140","trigger":"hover","content":"Type and enter to add"}},[_c('el-button',{staticClass:"info-button",attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"el-icon-info"})])],1)],1):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]):_c('el-col',{ref:"colRef",style:(_vm.computedStyles),attrs:{"span":_vm.showLabel ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel)?_c('span',{staticStyle:{"color":"white","display":"inline-block","height":"20px","opacity":"0"}}):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e()]),_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 12 : 24}},[(
            !_vm.isView &&
            !_vm.data.default_value &&
            _vm.data.properties.filed_content != 'Hide'
          )?_c('div',[(!_vm.isList)?_c('el-select',{class:{ 'highlighted-border': _vm.highlight },staticStyle:{"display":"flex","align-items":"center","flex-wrap":"nowrap"},style:(_vm.getStyle),attrs:{"collapse-tags":"","filterable":true,"multiple":"","disabled":_vm.isFromDocument ? false : _vm.checkReadonly() || _vm.readonly(),"readonly":_vm.readonly(),"clearable":"","allow-create":_vm.data.allow_to_create,"default-first-option":_vm.data.allow_to_create,"placeholder":_vm.data.placeholder},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},_vm._l((_vm.getOptions()),function(option,index){return _c('el-option',{key:index,attrs:{"value":option,"disabled":_vm.disabledOptions.includes(option)}})}),1):_vm._e(),(_vm.isList)?_c('el-select',{style:(_vm.getStyle),attrs:{"placeholder":_vm.data.placeholder,"popper-append-to-body":""},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},_vm._l((_vm.options),function(option,index){return _c('el-option',{key:index,attrs:{"value":option.value,"label":option.name}})}),1):_vm._e()],1):_vm._e(),(
            !_vm.isView &&
            _vm.data.default_value &&
            this.$route.name != 'EntityDataEditStep' &&
            _vm.data.properties.filed_content != 'Hide'
          )?_c('div',[_c('el-select',{style:(_vm.getStyle),attrs:{"collapse-tags":"","multiple":"","disabled":_vm.isFromDocument ? false : _vm.checkReadonly() || _vm.readonly(),"readonly":_vm.readonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},_vm._l((_vm.getOptions()),function(option,index){return _c('el-option',{key:index,attrs:{"value":option}})}),1)],1):_vm._e(),(_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('p',{style:(_vm.getStyle)},[(
              _vm.form[_vm.data.key] &&
              _vm.form[_vm.data.key].length &&
              typeof _vm.form[_vm.data.key] == 'object'
            )?[_vm._v(_vm._s(_vm.form[_vm.data.key].join(",")))]:[_vm._v("-")]],2):_vm._e()])],1)],1):_c('div',[(_vm.isView && _vm.form[_vm.data.key] && typeof _vm.form[_vm.data.key] == 'object')?_c('div',[_vm._v(" "+_vm._s(_vm.form[_vm.data.key].join(","))+" ")]):_c('div',[_c('el-select',{style:(_vm.getStyle),attrs:{"collapse-tags":"","multiple":"","disabled":_vm.isFromDocument ? false : _vm.checkReadonly() || _vm.readonly(),"readonly":_vm.readonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},_vm._l((_vm.getOptions()),function(option,index){return _c('el-option',{key:index,attrs:{"value":option}})}),1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }