<template>
  <div v-if="!refresh && data.properties.filed_content != 'Hide'">
    <div
      :style="getElementStyle"
      v-if="!loading && !profilePage && data.properties.filed_content != 'Hide'"
    >
      <el-row align="middle">
        <el-col
          v-if="!data.properties.hideLabel"
          :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
          :style="computedStyles"
        >
          <label
            for="text"
            v-if="hasLabel && data.properties.filed_content != 'Hide'"
            :style="getLabelStyles"
            >{{ label || data.label }}</label
          >
          <span
            v-if="data.validations.required && hasLabel && !isView"
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <span v-if="data.allow_to_create && hasLabel">
            <el-popover
              placement="top-start"
              width="140"
              trigger="hover"
              content="Type and enter to add"
            >
              <el-button class="info-button" slot="reference">
                <i class="el-icon-info"></i
              ></el-button>
            </el-popover>
          </span>
          <span>
            <el-popover
              v-if="data.description"
              placement="top-start"
              trigger="hover"
              :content="data.description"
            >
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </span>
        </el-col>
        <el-col
          v-else
          :span="showLabel ? 4 : data.description ? 20 : 24"
          ref="colRef"
          :style="computedStyles"
        >
          <span
            v-if="hasLabel"
            style="
              color: white;
              display: inline-block;
              height: 20px;
              opacity: 0;
            "
          ></span>
          <span
            v-if="data.validations.required && hasLabel && !isView"
            style="color: red; font-weight: bold"
          >
            *
          </span>
        </el-col>
        <!-- <el-col :span="2">
          <el-popover v-if="data.description" placement="top-start" trigger="hover" :content="data.description">
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </el-col> -->
        <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
          <div
            v-if="
              !isView &&
              !data.default_value &&
              data.properties.filed_content != 'Hide'
            "
          >
            <el-select
              v-if="!isList"
              collapse-tags
              :filterable="true"
              :style="getStyle"
              v-model="form[data.key]"
              multiple
              :disabled="isFromDocument ? false : checkReadonly() || readonly()"
              :readonly="readonly()"
              @change="applyFormRules"
              clearable
              style="display: flex; align-items: center; flex-wrap: nowrap"
              :allow-create="data.allow_to_create"
              :default-first-option="data.allow_to_create"
              :class="{ 'highlighted-border': highlight }"
              :placeholder="data.placeholder"
            >
              <!-- Add your options here -->
              <el-option
                v-for="(option, index) of getOptions()"
                :value="option"
                :key="index"
                :disabled="disabledOptions.includes(option)"
              ></el-option>
            </el-select>
            <el-select
              v-if="isList"
              :style="getStyle"
              v-model="form[data.key]"
              :placeholder="data.placeholder"
              popper-append-to-body
            >
              <el-option
                v-for="(option, index) of options"
                :value="option.value"
                :label="option.name"
                :key="index"
              ></el-option>
            </el-select>
          </div>
          <!-- <div v-if="!isView &&  this.$route.name == 'EntityDataEditStep'">
          <el-select collapse-tags   :style="getStyle" v-model="form[data.key]" multiple :disabled="isFromDocument ? false : checkReadonly() " :readonly="readonly()">
            <el-option v-for="(option,index) of data.options" :value="option" :key="index"></el-option>
          </el-select>
        </div> -->
          <div
            v-if="
              !isView &&
              data.default_value &&
              this.$route.name != 'EntityDataEditStep' &&
              data.properties.filed_content != 'Hide'
            "
          >
            <el-select
              collapse-tags
              :style="getStyle"
              v-model="form[data.key]"
              multiple
              :disabled="isFromDocument ? false : checkReadonly() || readonly()"
              :readonly="readonly()"
              @change="applyFormRules"
            >
              <el-option
                v-for="(option, index) of getOptions()"
                :value="option"
                :key="index"
              ></el-option>
            </el-select>
          </div>
          <p
            v-if="isView && data.properties.filed_content != 'Hide'"
            :style="getStyle"
          >
            <template
              v-if="
                form[data.key] &&
                form[data.key].length &&
                typeof form[data.key] == 'object'
              "
              >{{ form[data.key].join(",") }}</template
            >
            <template v-else>-</template>
          </p>
        </el-col>
      </el-row>
      <!--<label for="text" v-if="hasLabel" :style="getLabelStyles">{{data.label}}</label>
    <el-popover
      v-if="data.description"
      placement="top-start"
      trigger="hover"
      :content="data.description"
    >
      <i class="el-icon-info" slot="reference"></i>
    </el-popover>
    <div v-if="!isView">
      <el-select v-model="form[data.key]" multiple :disabled="checkReadonly()">
        <el-option v-for="(option,index) of data.options" :value="option" :key="index"></el-option>
      </el-select>
    </div>
    <p v-if="isView" :style="getStyle">
      <template v-if="form[data.key] && form[data.key].length">{{form[data.key].join(',') }}</template>
      <template v-else>-</template>
    </p>-->
    </div>
    <div v-else>
      <div v-if="isView && form[data.key] && typeof form[data.key] == 'object'">
        {{ form[data.key].join(",") }}
      </div>
      <div v-else>
        <el-select
          collapse-tags
          :style="getStyle"
          v-model="form[data.key]"
          multiple
          :disabled="isFromDocument ? false : checkReadonly() || readonly()"
          :readonly="readonly()"
          @change="applyFormRules"
        >
          <el-option
            v-for="(option, index) of getOptions()"
            :value="option"
            :key="index"
          ></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { mapGetters } from "vuex";

export default {
  name: "templates-formComponentsExecute-MultiSelectExecute",
  mixins: [userPermissionsHelper],
  components: {},
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isActive",
    "isSelectView",
    "isFromDocument",
    "checkIsDisabled",
    "checkhideFields",
    "profilePage",
    "colorFields",
    "highlight",
    "hideOptions",
  ],
  data() {
    return {
      validations: [],
      options: [],
      showLabel: true,
      isList: false,
      isDefalutPosq: true,
      loading: false,
      refresh: true,
      previous: [],
    };
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    getStyle() {
      return `width: 100%`;
    },
    getMaxValue() {
      return this.data.max_selection || undefined;
    },
    disabledOptions() {
      const selectedCount = this.form[this.data.key]?.length || 0;
      const maxSelections = this.getMaxValue; 
      if (maxSelections !== undefined && selectedCount >= maxSelections) {
        return this.options.filter(option => !this.form[this.data.key].includes(option));
      }
      return [];
    },
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
  },
  mounted() {
    if (this.data?.options) {
      this.options = JSON.parse(JSON.stringify(this.data.options));
    }
    const hideOptionsForKey = this.hideOptions?.[this.data.key];
    if (Array.isArray(hideOptionsForKey) && hideOptionsForKey.length) {
      this.previous = JSON.parse(JSON.stringify(hideOptionsForKey));
    }
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    this.loading = true;
    // this.data.options = this.getOptions();
    //this.defaultMethod();
    //check data format
    this.checkDataFormat();
    this.loading = false;
    setTimeout(() => {
      this.refresh = false;
    }, 100);
  },
  methods: {
    getOptions() {
      const formData = this.form[this.data.key];
      if (
        formData !== undefined &&
        formData !== null &&
        typeof formData[Symbol.iterator] === "function"
      ) {
        return Array.from(new Set([...this.options, ...formData]));
      } else {
        return this.options;
      }
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "LIST") {
          this.isList = true;
          this.options = this.getGlobalVariableById.list_data;
          this.data.is_list = true;
        } else {
          this.options = this.getGlobalVariableById.options;
          this.data.is_list = false;
        }
      }
    },
    defaultMethod() {
      if (
        this.data.default_value &&
        this.form &&
        this.data.key &&
        !this.form[this.data.key]
      ) {
        //  this.form[this.data.key] = this.data.default_value;
        this.$set(this.form, this.data.key, this.data.default_value);
      } else if (
        !this.form[this.data.key] &&
        this.data.default_mapped_field &&
        this.form[this.data.default_mapped_field]
      ) {
        this.$set(
          this.form,
          this.data.key,
          this.form[this.data.default_mapped_field]
        );
      }
      this.$emit("applyFormRules");
    },
    checkDataFormat() {
      if (
        this.form[this.data.key] &&
        typeof this.form[this.data.key] == "string"
      ) {
        this.$set(this.form, this.data.key, [this.form[this.data.key]]);
      }
    },
    applyFormRules() {
      // this.data.options = this.getOptions();
      this.$emit("applyFormRules");
    },
    // checkReadonly() {
    //   if(this.checkIsDisabled){
    //     return true
    //   }
    //   if(this.data.styles && this.data.styles.labelStyle){
    //     if(this.data.styles.labelStyle == 'hide'){
    // // checkReadonly() {
    // //   if(this.data.styles && this.data.styles.labelStyle){
    // //     if(this.data.styles.labelStyle == 'hide'){
    // //       this.showLabel = false;
    // //     }
    // //     if(this.data.styles.labelStyle == 'right'){
    // //       this.isDefalutPos = false;
    // //     }
    // //   }
    // //   return !this.fieldFilledByCurrentUser(this.data.filled_by);
    // // }
    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
  },
  watch: {
    "data.is_global_variable": {
      immediate: true,
      handler(val, oldVal) {
        if (val && !oldVal && this.data.global_variable_id) {
          this.setGlobalVariableData();
        }
        if (!val) {
          this.options = this.data.options || [];
          this.isList = false;
        }
      },
    },
    isActive: {
      immediate: true,
      handler(val, oldVal) {
        if (this.isSelectView) {
          this.options = this.data.options || [];
        }
        console.log(val, oldVal);
      },
    },
    hideOptions: {
      handler(data) {
        if (
          data?.[this.data.key] &&
          JSON.stringify(this.previous) !== JSON.stringify(data[this.data.key])
        ) {
          if (data?.[this.data.key].length) {
            let removeIndices = [];
            data[this.data.key].map((op, i) => {
              if (this.options.includes(op)) {
                removeIndices.push(i);
              }
            });
            if (removeIndices.length) {
              removeIndices.sort((a, b) => b - a);
              removeIndices.map((i) => {
                this.options.splice(i, 1);
              });
            }
          } else {
            if (this.data.is_global_variable && this.data.global_variable_id) {
              this.setGlobalVariableData();
            } else {
              this.options = JSON.parse(JSON.stringify(this.data.options));
            }
          }
          this.previous = JSON.parse(JSON.stringify(data[this.data.key]));
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.el-select .el-select__tags > span {
  display: flex;
}
</style>
<style scoped>
.highlighted-border {
  border-color: red !important;
}
.info-button {
  border: none;
  margin-left: 10px;
  padding: 2px;
}
</style>
