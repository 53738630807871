<template>
  <div>
    <el-form label-position="right" class="mr-10">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <!-- <el-col :span="6">
          <placeholder :field="field" />
        </el-col> -->
        <!-- <el-col :span="6">
            <el-link
              @click="duplicateRulesModal = !duplicateRulesModal"
              :underline="false"
              type="primary"
              class="mt-1"
            >
              Duplicate rules
            </el-link>
          </el-col> -->
        <el-col :span="8">
          <el-link
            :underline="false"
            type="primary"
            @click="openFormRulesModal"
          >
            Form rules
          </el-link>
        </el-col>
        <!-- <el-col :span="6">
            <el-link
              @click=" templateRulesDialog = !templateRulesDialog"
              :underline="false"
              type="primary"
              class="mt-1"
            >
              Form rules
            </el-link>
          </el-col> -->
        <el-col :span="8">
          <span>Default no.of rows open</span><br />
          <el-input-number
            size="mini"
            v-model="field.default_rows"
          ></el-input-number>
        </el-col>
        <el-col :span="8">
          <field-filled-by :field="field" />
          <el-select
            v-model="field.table_data_view_type"
            placeholder="Select View Type"
          >
            <el-option
              v-for="item in viewOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-if="field.table_data_view_type == 'Accordion_view'"
            v-model="field.selected_header_title"
            placeholder="Select header ttile"
            class="mt-1"
          >
            <el-option
              v-for="(column, index) in field.data_table_columns"
              :key="index"
              :label="column.label"
              :value="column.label"
            />
          </el-select>
          <!-- <el-switch
            aria-label="switched key"
              v-model="field.allow_deletion"
              active-text="Allow Data Deletion"
              inactive-text="Do Not Allow Data Deletion"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="handleSwitchChange">
            </el-switch> -->
          <!-- <el-checkbox label="Allow Data Deletion" v-model="field.allow_deletion" @change="handleSwitchChange">Allow Data Deletion</el-checkbox> -->
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="10" class="mt-1">
        <el-col>
          <el-form-item label="Select Entity">
            <span class="red-asterisk">*</span>
            <br />
            <el-select
              v-loading="loading"
              filterable
              @change="getEntityFields"
              v-model="field.entity_id"
            >
              <el-option
                v-for="(entity, index) in allEntities"
                :key="index"
                :value="entity._id"
                :label="entity.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="Select Type">
            <span class="red-asterisk">*</span><br />
            <el-select v-model="field.child_table_type">
              <el-option value="interactive" label="Edit"></el-option>
              <el-option value="view" label="View"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col>
          <el-checkbox
            label="Link to Parent Entity"
            v-model="field.data_table_field_auto_fill"
            @change="emptyRelatedFilters"
            >Link to Parent Entity</el-checkbox
          >
          <el-tooltip
            content="If this template is placed in the selected entity, the corresponding entity field will not be shown in the table and will be autofilled."
          >
            <i class="el-icon-info"></i> </el-tooltip
          ><br /><br />
          <el-select
            v-if="field.data_table_field_auto_fill"
            v-model="field.selected_auto_fill_entity"
            filterable
            v-loading="autoLoading"
          >
            <el-option
              v-for="(entity, index) in fieldTypeRelationships"
              :key="index"
              :value="entity._id"
              :label="entity.name"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col v-if="field.child_table_type == 'interactive'">
          <el-checkbox
            label="Allow Data Deletion"
            v-model="field.allow_deletion"
            >Allow Data Deletion</el-checkbox
          >
        </el-col>
        <el-col v-if="field.child_table_type == 'interactive'">
          Min & Max
          <div class="d-flex" style="gap: 5px">
            <div>
              <el-input
                type="number"
                v-model="field.min_value"
                size="mini"
                placeholder="Min value"
                :disabled="field.min_value_map ? true : false"
                clearable
              ></el-input>
              OR
              <el-select
                filterable
                v-model="field.min_value_map"
                size="mini"
                placeholder="Map min value"
                :disabled="field.min_value ? true : false"
                clearable
              >
                <el-option
                  v-for="(entity, index) in getFieldsByType(
                    ['NUMBER'],
                    allFieldsData
                  )"
                  :key="index + '_key_' + entity.key"
                  :value="entity.key"
                  :label="entity.label"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <el-input
                type="number"
                v-model="field.max_value"
                size="mini"
                placeholder="Max value"
                :disabled="field.max_value_map ? true : false"
                clearable
              ></el-input>
              OR
              <el-select
                filterable
                v-model="field.max_value_map"
                size="mini"
                placeholder="Map max value"
                :disabled="field.max_value ? true : false"
                clearable
              >
                <el-option
                  v-for="(entity, index) in getFieldsByType(
                    ['NUMBER'],
                    allFieldsData
                  )"
                  :key="index + '_key_' + entity.key"
                  :value="entity.key"
                  :label="entity.label"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-checkbox
          v-model="field.child_table_auto_fill"
          v-if="field.child_table_type == 'interactive'"
          >Auto Fill</el-checkbox
        >
        <EntityFilters
          v-if="field.child_table_auto_fill || field.child_table_type == 'view'"
          :field="field"
          :fieldsData="allFieldsData"
        ></EntityFilters>
      </el-row>
    </el-form>
    <br />
    <template
      v-if="
        field.data_table_columns &&
        field.data_table_columns.length &&
        field.table_data_view_type == 'table_view'
      "
    >
      <el-row class="mr-10">
        <u>Drag Fields to Change Column order</u>
        <el-scrollbar class="elementsScroll">
          <table class="table" style="z-index: 1000; border: 10px">
            <tr>
              <draggable v-model="field.data_table_columns" tag="tr">
                <th
                  v-for="(column, index) in field.data_table_columns"
                  :key="index"
                >
                  <el-tag effect="dark">
                    {{ column.label }}
                  </el-tag>
                </th>
              </draggable>
            </tr>
          </table>
        </el-scrollbar>
      </el-row>
    </template>
    <hr />
    <template
      v-if="field.data_table_columns && field.data_table_columns.length"
    >
      <div class="mr-10" v-if="field.table_data_view_type == 'table_view'">
        <div class="resizable-wrapper">
          <p>Resize Columns Below</p>
        </div>
        <div class="resizable-wrapper">
          <div class="table table-striped">
            <div
              v-for="(column, index) in field.data_table_columns"
              :key="index"
              :style="{ minWidth: column.width + 'px' }"
              class="mr-1"
            >
              <ResizableComponent
                :field="column"
                @resize="(data) => updateFiedSize(index, data)"
                :x="getXvalueForField(field.data_table_columns, index)"
              ></ResizableComponent>
            </div>
          </div>
        </div>
      </div>
    </template>
    <dialog-component
      :visible="formRulesModal"
      title="Form rules"
      :containerWidth="'45%'"
      :containerHeight="'60%'"
      @before-close="appendRules"
    >
      <template-form-rules-widget
        :allFields="
          selectedEntityFieldData && selectedEntityFieldData.data_table_columns
            ? selectedEntityFieldData.data_table_columns
            : []
        "
        :templateRules="fieldRules"
        :fromDataTable="true"
        @form-rule-added="updateFormRules"
        @form-rule-delete="deleteFormRules"
      >
      </template-form-rules-widget>
    </dialog-component>
  </div>
</template>

<script>
import draggable from "vuedraggable";
//import { dataTableAllowedViewFields } from "../formComponentsView/index";
import ResizableComponent from "./ResizableComponent";
import AutomationHelper from "@/mixins/AutomationHelper";
import EntitiesHelper from "@/mixins/EntitiesHelper";
// import TemplateFormRulesWidget from "../../widgets/TemplateFormRulesWidget.vue";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
// import { mapGetters } from "vuex";
export default {
  name: "templates-formComponents-DataTable",
  mixins: [AutomationHelper, EntitiesHelper, TemplateBuilderHelper],
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    // Placeholder: () => import("./Placeholder"),
    EntityFilters: () => import("./EntityFilters"),
    draggable,
    //   DuplicateDataRulesWidget: () =>
    //     import("../../widgets/DuplicateDataRulesWidget.vue"),

    //   ...dataTableAllowedViewFields,
    ResizableComponent,
    //   // TemplateFormRulesWidget,
    TemplateFormRulesWidget: () =>
      import("../../widgets/TemplateFormRulesWidget.vue"),
  },
  props: ["field", "allFieldsData"],
  computed: {
    // ...mapGetters("entities", ["getAllEntities"]),
  },
  data() {
    return {
      loading: false,
      validations: [],
      headers: ["id", "name", "sport"],
      list: [
        { id: 1, name: "Abby", sport: "basket" },
        { id: 2, name: "Brooke", sport: "foot" },
        { id: 3, name: "Courtenay", sport: "volley" },
        { id: 4, name: "David", sport: "rugby" },
      ],
      viewOptions: [
        {
          value: "table_view",
          label: "Table",
        },
        {
          value: "Accordion_view",
          label: "Accordion",
        },
      ],
      duplicateRulesModal: false,
      templateRulesDialog: false,
      fieldsData: [],
      templateRules: [],
      formRulesModal: false,
      fieldRules: [],
      addDataTableStepFilters: false,
      dataTablefilters: [],
      dataTablefiltersQuery: "AND",
      selectedEntityFieldData: {},
      allEntities: [],
      fieldTypeRelationships: [],
      autoLoading: false,
    };
  },
  async mounted() {
    this.loading = true;
    this.autoLoading = true;
    this.allEntities = await this.fetchAllEntities(false);
    if (this.allEntities) {
      // this.allEntities = this.getAllEntities.data.filter(
      //   (e) => e && e.status == "ACTIVE"
      // );
      let entityFieldIds = this.field.data_table_columns
        .filter((e) => e.inputType == "ENTITY")
        .map((f) => f.entity_id);
      this.fieldTypeRelationships = this.allEntities.filter((e) =>
        entityFieldIds.includes(e._id)
      );
    }
    this.selectedEntityFieldData = {};
    if (this.field?.data_table_columns?.length) {
      this.selectedEntityFieldData = { ...this.field };
    }
    this.autoLoading = false;
    this.loading = false;
  },
  methods: {
    emptyRelatedFilters() {
      if (this.field.data_table_field_auto_fill) {
        this.field.filters.map((flt, index) => {
          if (flt.field == "self#related_to/parent") {
            this.field.filters[index].field = "";
          } else {
            let value_field = this.fieldsData.find(
              (fd) => fd.key == flt.value_field
            );
            if (value_field.entity_id == this.field.selected_auto_fill_entity) {
              this.field.filters[index].value_field = "";
            }
          }
        });
      }
    },
    handleSwitchChange() {},
    saveFilters() {
      this.$set(this.field, "data_table_filters", this.dataTablefilters);
      this.$set(
        this.field,
        "data_table_filter_query",
        this.dataTablefiltersQuery
      );
      this.resetDataTableFilters();
    },
    appendRules() {
      this.field["rules"] = [...this.fieldRules];
      // this.$emit("data-table-rules-updates", this.fieldRules, this.index);
      this.fieldRules = [];
      this.formRulesModal = false;
    },
    deleteFormRules(index) {
      this.fieldRules.splice(index.index, 1);
    },
    updateFormRules(data) {
      if (data.index == -1) {
        this.fieldRules.push(data);
      } else {
        this.fieldRules[data.index] = { ...data };
      }
    },
    openFormRulesModal() {
      this.fieldRules = [...(this.field.rules || [])];
      this.formRulesModal = true;
    },
    onResize(x, y, width, height, field) {
      field.width = width;
      field.height = height;
      field.x = x;
      field.y = y;
    },
    setData() {},
    updateFiedSize(index, data) {
      if (this.field.data_table_columns[index]) {
        this.field.data_table_columns[index].height = data.height;
        this.field.data_table_columns[index].width = data.width;
        const allFieldsWith = this.getAllFieldsWidth();

        if (this.field.width < allFieldsWith) {
          this.field.width = allFieldsWith;
        }
      }
    },
    getXvalueForField(data_table_columns = [], index) {
      if (index == 0) {
        return 10;
      }

      let previousFields = data_table_columns.slice(0, index);
      var result = previousFields.reduce(function (acc, obj) {
        return obj.width;
      }, 0);
      return 30 + result;
    },
    getAllFieldsWidth() {
      let previousFields = this.field.data_table_columns;
      var result = previousFields.reduce(function (acc, obj) {
        return obj.width;
      }, 0);

      return result;
    },
    async getEntityFields() {
      this.autoLoading = true;
      this.field.data_table_columns = [];
      let entity = this.allEntities.find((e) => e._id == this.field.entity_id);
      if (entity) {
        let allFields = await this.fetchEntityDetails(
          this.field.entity_id,
          false,
          false,
          false,
          true
        );
        if (entity.settings.length) {
          let columns = entity.settings
            .filter(
              (fie) =>
                ![
                  "created_at",
                  "updated_at",
                  "created_by",
                  "updated_by",
                  "profile_picture_with_name",
                ].includes(fie.template_id)
            )
            .map((fie) => {
              let field = allFields.find(
                (f) => f.key == fie.id && f.template_id == fie.template_id
              );
              if (field) {
                field.key = field.template_id + "@" + field.key;
                if (field.input_type == "FORMULA") {
                  field.selected_fields.map((sf) => {
                    if (sf.type == "FIELD") {
                      sf.key = field.template_id + "@" + sf.key;
                    }
                  });
                } else if (field.input_type == "ENTITY_VARIABLE") {
                  field.relationship_key =
                    field.template_id + "@" + field.relationship_key;
                }
                return field;
              } else {
                return null;
              }
            });
          this.field.data_table_columns = columns.filter((e) => e);
        } else {
          let result = allFields.filter(
            (o) =>
              o.template_id === entity?.templates?.[0].template_id &&
              !["DATA_TABLE", "ENTITY_TABLE"].includes(o.input_type) &&
              !["DATA_TABLE", "ENTITY_TABLE"].includes(o.inputType)
          );
          let columnFields = result?.length ? result : entity.primaryFields;
          let columns = columnFields.map((fie) => {
            let field = allFields.find(
              (e) =>
                e.key == fie.key &&
                e.template_id.toString() == fie.template_id.toString()
            );
            if (field) {
              field.key = field.template_id + "@" + field.key;
              if (field.input_type == "FORMULA") {
                field.selected_fields.map((sf) => {
                  if (sf.type == "FIELD") {
                    sf.key = field.template_id + "@" + sf.key;
                  }
                });
              } else if (field.input_type == "ENTITY_VARIABLE") {
                field.relationship_key =
                  field.template_id + "@" + field.relationship_key;
              } else if (field.inputType == "ENTITY") {
                if (field.filters && field.filters.length) {
                  field.filters.map((e) => {
                    if (e.value_field !== "") {
                      e.value_field = field.template_id + "@" + e.value_field;
                    }
                  });
                }
              }
              return field;
            }
          });
          this.field.data_table_columns = columns.filter((e) => e);
        }
      }
      let entityFieldIds = this.field.data_table_columns
        .filter((e) => e && e.inputType == "ENTITY")
        .map((f) => f.entity_id);
      this.fieldTypeRelationships = this.allEntities.filter((e) =>
        entityFieldIds.includes(e._id)
      );
      this.selectedEntityFieldData = {};
      this.selectedEntityFieldData = { ...this.field };
      this.autoLoading = false;
    },
  },
};
</script>

<style lang="scss">
.elementsScroll {
  height: 50px;
}
.paragraphScroll {
  height: 120px;
  max-height: 300px;
  overflow-x: auto;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
}
.paragraphScroll::-webkit-scrollbar {
  height: 0.5em;
}
.paragraphScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.table-striped {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  padding: 1.5rem;
  z-index: 1000;
  border: 10px;
  max-width: 1180px;
  height: 7rem;
  background-color: antiquewhite;
  overflow-x: auto;
}

.resizable-wrapper {
  display: flex;
  justify-content: center;
}
</style>
