<template>
  <div v-if="editor" v-loading="loading">
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import Mention from "@tiptap/extension-mention";
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-2";
import tippy from "tippy.js";
import { VueRenderer } from "@tiptap/vue-2";
import MentionList from "./MentionList.vue";
import { mapGetters } from "vuex";
// import suggestion from "./suggestion";

export default {
  components: {
    EditorContent,
  },
  computed: {
    ...mapGetters("entities", ["getEntityDataById"]),
  },
  data() {
    return {
      editor: null,
      allFields: [],
      loading: false,
    };
  },
  props: ["entity_id", "data", "fromNotifications"],
  async mounted() {
    this.loading = true;
    if (this.entity_id) {
      await this.fetchEntityDetails(this.entity_id);
      this.loading = false;
    }
  },
  methods: {
    async fetchEntityDetails(id) {
      await this.$store.dispatch("entities/fetchEntityById", { entity_id: id });
      if (this.getEntityDataById && this.getEntityDataById.templates) {
        this.getEntityDataById.templates.forEach((template) => {
          if (template?.templateInfo?.sections[0]?.fields) {
            this.allFields = [
              ...this.allFields,
              ...this.getTemplateFields(template?.templateInfo, id),
            ];
          }
        });
        this.editor = new Editor({
          onUpdate: ({ editor }) => {
            if (this.fromNotifications) {
              const text = editor.getText();
              this.data.custom_message = text;
            } else {
              const json = editor.getJSON();
              this.data.custom_message = { ...json };
            }
          },
          extensions: [
            StarterKit,
            Mention.configure({
              HTMLAttributes: {
                class: "mention",
              },
              suggestion: {
                items: ({ query }) => {
                  return this.allFields
                    .filter(
                      (item) =>
                        item?.label &&
                        item?.key &&
                        item.label.toLowerCase().startsWith(query.toLowerCase())
                    )
                    .slice(0, 30);
                },

                render: () => {
                  let component;
                  let popup;

                  return {
                    onStart: (props) => {
                      component = new VueRenderer(MentionList, {
                        // using vue 2:
                        parent: this,
                        propsData: props,
                        // using vue 3:
                        //   props,
                        //   editor: props.editor,
                      });

                      if (!props.clientRect) {
                        return;
                      }

                      popup = tippy("body", {
                        getReferenceClientRect: props.clientRect,
                        appendTo: () => document.body,
                        content: component.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: "manual",
                        placement: "bottom-start",
                      });
                    },

                    onUpdate(props) {
                      component.updateProps(props);

                      if (!props.clientRect) {
                        return;
                      }

                      popup[0].setProps({
                        getReferenceClientRect: props.clientRect,
                      });
                    },

                    onKeyDown(props) {
                      if (props.event.key === "Escape") {
                        popup[0].hide();

                        return true;
                      }

                      return component.ref?.onKeyDown(props);
                    },

                    onExit() {
                      popup[0].destroy();
                      component.destroy();
                    },
                  };
                },
              },
            }),
          ],
          content: this.data.custom_message,
        });
      }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        e.label = (this.fromNotifications ? data._id : data.slug) + "#" + e.key;
        e.template_key = data._id + "#" + e.key;
        e.template_name = data.name;
        return e;
      });
      return fields;
    },
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
}

.mention {
  border: 1px solid #000;
  border-radius: 0.4rem;
  padding: 0.1rem 0.3rem;
  box-decoration-break: clone;
}
</style>
