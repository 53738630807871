<template>
  <section class="all-formbuilders-view">
    <h1 style="font-weight: 600">My Formbuilders</h1>
    <div class="vue-data-table-default">
      <div>
        <data-tables-server
          :data="allFormbuilders"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationPropsForTable"
          :total="total"
          style="width: 100%"
          v-loading="loading"
          v-if="!getIsMobile"
          @query-change="loadData"
          @sort-change="sortChange"
          @change="loadData"
        >
          <el-table-column label="Name" width="350">
            <template slot-scope="scope">
              <i class="el-icon-notebook-2"></i>
              <span style="margin-left: 10px">{{
                scope.row.formbuilders_details_id.name
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Created at" width="280">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{
                scope.row.created_at | globalDateTimeFormat
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Updated at" width="280">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{
                scope.row.updated_at | globalDateTimeFormat
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Completion Status" width="280">
            <template slot-scope="scope">
              <div class="warning" v-if="scope.row.formbuilder_data_status == 'PENDING' ||
                  scope.row.formbuilder_data_status == 'SENT'">
                <i class="el-icon-refresh"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "PENDING" ||
                    scope.row.formbuilder_data_status == "SENT"
                    ? "Pending"
                    : ""
                  }}
                </span>
              </div>

              <div class="success" v-else-if="scope.row.formbuilder_data_status == 'COMPLETED'">
                <i class="el-icon-success"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "COMPLETED"
                    ? "Completed"
                    : ""
                  }}
                </span>
              </div>

              <div class="expired-div" v-else-if="scope.row.formbuilder_data_status == 'EXPIRED'">
                <i class="el-icon-error"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "EXPIRED"
                    ? "Expired"
                    : ""
                  }}
                </span>
              </div>

              <div v-else>
                 Pending
              </div>
            </template>
          </el-table-column>
        </data-tables-server>
      </div>
    </div>
    <div>
      <data-tables-server
        :data="allFormbuilders"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pagination-props="paginationPropsForTable"
        :total="total"
        style="width: 100%"
        v-loading="loading"
        v-if="getIsMobile"
        @query-change="loadData"
        @sort-change="sortChange"
        @change="loadData"
      >
        <el-table-column label="Name" width="350">
          <template slot-scope="scope">
            <i class="el-icon-notebook-2"></i>
            <span style="margin-left: 10px">{{
              scope.row.formbuilders_details_id.name
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Completion Status">
          <template slot-scope="scope">
              <div class="warning" v-if="scope.row.formbuilder_data_status == 'PENDING' ||
                  scope.row.formbuilder_data_status == 'SENT'">
                <i class="el-icon-refresh"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "PENDING" ||
                    scope.row.formbuilder_data_status == "SENT"
                    ? "Pending"
                    : ""
                  }}
                </span>
              </div>

              <div class="success" v-else-if="scope.row.formbuilder_data_status == 'COMPLETED'">
                <i class="el-icon-success"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "COMPLETED"
                    ? "Completed"
                    : ""
                  }}
                </span>
              </div>

              <div class="expired-div" v-else-if="scope.row.formbuilder_data_status == 'EXPIRED'">
                <i class="el-icon-error"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "EXPIRED"
                    ? "Expired"
                    : ""
                  }}
                </span>
              </div>

              <div v-else>
                 Pending
              </div>
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="expand-column-item">
              <div class="expand-column-item-label">Created at:</div>
              <div class="expand-column-item-content">
                <template v-if="scope.row.created_at">
                  <i class="el-icon-time"></i>
                  <span style="margin-left: 10px">
                    {{ scope.row.created_at | globalDateTimeFormat }}
                  </span>
                </template>
              </div>
            </div>
            <div class="expand-column-item">
              <div class="expand-column-item-label">Updated at:</div>
              <div class="expand-column-item-content">
                <template v-if="scope.row.updated_at">
                  <i class="el-icon-time"></i>
                  <span style="margin-left: 10px">
                    {{ scope.row.updated_at | globalDateTimeFormat }}
                  </span>
                </template>
              </div>
            </div>
          </template>
        </el-table-column>
      </data-tables-server>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      allFormbuilders: [],
      pageSize: 10,
      total: 0,
      currentPage: 1,
      page: 1,
      loading: false,
      order_type: null,
      order_by: null,
    };
  },
  computed: {
    ...mapGetters("applicationUsers", ["getUserFormbuilders"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    paginationProps() {
      return [5, 10, 20, 50];
    },
    paginationPropsForTable() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
  },
  async mounted() {
    await this.fetchFormbuilders();
  },
  methods: {
    async fetchFormbuilders() {
      this.loading = true;
      this.allFormbuilders = [];
      let params = {
        limit: parseInt(this.pageSize),
        page: parseInt(this.page),
      };
      if (this.order_by) {
        params.order_by = this.order_by;
      }
      if (this.order_type) {
        params.order_type = this.order_type;
      }
      await this.$store.dispatch(
        "applicationUsers/fetchUserFormbuilders",
        params
      );
      if (this.getUserFormbuilders?.data) {
        this.allFormbuilders = this.getUserFormbuilders.data;
      }
      this.total = this.getUserFormbuilders.total;
      this.loading = false;
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchFormbuilders();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchFormbuilders();
      }
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchFormbuilders();
      }
    },
  },
};
</script>
<style scoped>
.text {
  font-size: 10;
  color: rgb(17, 14, 70);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expired-div{
  color: #dc3545;
}
</style>
